import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { getLogger } from 'loglevel';
import { BundleConfig, BundleModel, BundleSourceTypes, BundleType, BundleTypes, DefaultBundleModelKeys, Optional } from './types';
import { defaultGetDestinationPath, defaultIsAcceptable, getFullDestinationPath, getTypedDestinationPath } from './utils';
const logger = getLogger('[bundle-request]');
logger.setLevel('warn');

const DefaultBundleModel: Pick<BundleModel, DefaultBundleModelKeys> = {
  isAcceptable: defaultIsAcceptable,
  getItemId: (pathParts: string[]) => pathParts[pathParts.length - 1],
  getDestinationPath: defaultGetDestinationPath,
  getParams: (ids: string[]) => ({ id_in: ids }),
  itemResultIdFormatter: (v: any) => v.id,
  itemResultPayloadFormatter: (v: any) => v
} as const;

const BundleModels: Optional<BundleModel, DefaultBundleModelKeys>[] = [
  {
    type: BundleTypes.Cards,
    sourceType: BundleSourceTypes.Cards,
    getDestinationPath: getTypedDestinationPath
  },
  {
    type: BundleTypes.CardObjects,
    sourceType: BundleSourceTypes.Objects,
    isAcceptable: (requestConfig) => {
      const card = requestConfig.params?.card;
      return Boolean(requestConfig.params?.limit === 1 && (Array.isArray(card) ? card.length : card));
    },
    getItemId: (pathParts, requestConfig) => requestConfig.params.card,
    getDestinationPath: getTypedDestinationPath,
    getParams: (indexes: string[]) => ({ card: indexes, limit: 200 }),
    itemResultIdFormatter: (v: any) => v.card,
    itemResultPayloadFormatter: (v: any) => ({ results: [v] })
  },
  {
    type: BundleTypes.TypedObjects,
    sourceType: BundleSourceTypes.Objects,
    getDestinationPath: getTypedDestinationPath,
    isAcceptable: (requestConfig) => {
      const url = requestConfig.url;
      const parts = url?.split('/').filter(v => !!v) || [];
      return parts[0] === 'objects' && !!parts[1] && Number(parts[2]) > 0;
    }
  },
  {
    type: BundleTypes.Lines,
    sourceType: BundleSourceTypes.Lines,
    isAcceptable: (requestConfig) => {
      return defaultIsAcceptable(requestConfig) && requestConfig.url !== '/lines/attributes/';
    }
  },
  {
    type: BundleTypes.Cases,
    sourceType: BundleSourceTypes.Cases
  },
  {
    type: BundleTypes.Puppeteer,
    sourceType: BundleSourceTypes.Puppeteer,
    isAcceptable: (requestConfig) => {
      return requestConfig.params?.card && requestConfig.url === '/puppeteer/remote-monitoring/';
    },
    getItemId: (pathParts, requestConfig) => requestConfig.params.card,
    getDestinationPath: getFullDestinationPath,
    getParams: (indexes: string[]) => ({ card_in: indexes, limit: 200 }),
    itemResultIdFormatter: (v: any) => v.card,
    itemResultPayloadFormatter: (v: any) => ({ results: [v] })
  }
];

const EmbeddedBundleModels: BundleModel[] = BundleModels.map((v) => ({ ...DefaultBundleModel, ...v }));

function getBundleModelByType(bundleType: BundleType) {
  return EmbeddedBundleModels.find((v) => v.type === bundleType) || null;
}

function getBundleModelBySourceAndRequest(sourceType: string, requestConfig: AxiosRequestConfig) {
  const acceptableModels = EmbeddedBundleModels.filter((v) => v.sourceType === sourceType && v.isAcceptable(requestConfig));
  if (acceptableModels.length === 1) {
    return acceptableModels[0];
  }
  if (acceptableModels.length === 0) {
    return null;
  }
  throw new Error('Too much models found for bundling load. Expected 1 or 0 models, got: ' + acceptableModels.length);
}

const DefaultAdapter = axios.defaults.adapter!;
const BundleIntervalMs = 320;

class AxiosBundleRequestAdapter {
  bundleMap: Record<string, BundleConfig[]> = {};
  interval = 0;

  constructor() {
    this.handleRequest = this.handleRequest.bind(this);
    this.handleBundles = this.handleBundles.bind(this);
    this.interval = setInterval(this.handleBundles, BundleIntervalMs) as any;
  }

  getBundleConfig(requestConfig: AxiosRequestConfig) {
    const isSupportedMethod = requestConfig.method?.toLowerCase() === 'get';
    if (!isSupportedMethod) return null;

    const pathParts = requestConfig.url?.split('/').filter((v) => !!v) as string[];
    const sourceType = pathParts[0];
    const bundleModel = getBundleModelBySourceAndRequest(sourceType, requestConfig);
    if (!bundleModel) return null;

    const itemId = bundleModel.getItemId(pathParts, requestConfig);
    const destinationPath = bundleModel.getDestinationPath(pathParts);

    let resolveHandler = null;
    let rejectHandler = null;
    const promise = new Promise((r, j) => {
      resolveHandler = r;
      rejectHandler = j;
    });

    logger.info('[bundle] has support: ', bundleModel.type, requestConfig, destinationPath, itemId, resolveHandler, rejectHandler, promise);

    const bundleConfig: BundleConfig = {
      bundleType: bundleModel.type,
      requestConfig,
      destinationPath,
      itemId,
      resolveHandler,
      rejectHandler,
      promise
    };

    return bundleConfig;
  }

  async handleBundles() {
    for (let key in this.bundleMap) {
      const items = this.bundleMap[key];
      if (!items?.length) continue;
      logger.info('[bundle] handle ', key, ', count ', items.length);
      const ids = [...new Set(items.map((v) => JSON.stringify(v.itemId)))].map((v) => JSON.parse(v));
      const defaultBundleConfig = this.bundleMap[key][0];
      const { requestConfig, destinationPath, bundleType } = defaultBundleConfig;

      const bundleModel = getBundleModelByType(bundleType);
      if (!bundleModel) throw new Error("Can't get bundle model by type: " + bundleType);
      const getBundleKeyFunction = bundleModel.itemResultIdFormatter;
      const getResultFunction = bundleModel.itemResultPayloadFormatter;

      this.bundleMap[key] = [];
      requestConfig.url = destinationPath;
      requestConfig.params = { ...bundleModel.getParams(ids), limit: 1000 };

      DefaultAdapter(requestConfig)
        .then((v) => this.defaultSuccessHandler(v, items, getBundleKeyFunction, getResultFunction))
        .catch((e) => this.defaultRejectHandler(e, items));
    }
  }

  defaultSuccessHandler(v: AxiosRequestConfig, items: BundleConfig[], getBundleKeyFunction: any, getResultFunction: any) {
    const loadedItems = JSON.parse(v.data)?.results || [];
    logger.info('[bundle] resolve type ', items[0].bundleType, ', result ', loadedItems);
    const loadedItemsMap = loadedItems.reduce((m: Record<string, any>, v: any) => {
      m[getBundleKeyFunction(v)] = v;
      return m;
    }, {});
    items.forEach((bundleConfig) => {
      const loadedItem = loadedItemsMap[bundleConfig.itemId];
      logger.info('Success handler: ', loadedItem, bundleConfig.destinationPath, bundleConfig.itemId, bundleConfig.bundleType);
      if (loadedItem) {
        const response = { ...v };
        response.data = getResultFunction(loadedItem);
        bundleConfig.resolveHandler(response);
      } else {
        logger.warn('Reject handler: ', bundleConfig.itemId);
        bundleConfig.rejectHandler('not_found');
      }
    });
  }

  defaultRejectHandler(e: Error, items: BundleConfig[]) {
    items.forEach((bundleConfig) => {
      bundleConfig.rejectHandler(e);
    });
  }

  handleRequest(config: AxiosRequestConfig): AxiosPromise {
    const bundleConfig = this.getBundleConfig(config);

    if (bundleConfig) {
      const type = bundleConfig.bundleType + '--' + bundleConfig.destinationPath;
      if (!this.bundleMap[type]) this.bundleMap[type] = [];
      this.bundleMap[type].push(bundleConfig);
      return bundleConfig.promise;
    }

    return DefaultAdapter(config).then(async (v) => {
      return v;
    });
  }
}

export const axiosBundleAdapter = new AxiosBundleRequestAdapter();
