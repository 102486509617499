
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { NButton } from '@/uikit';
import isNumber from 'lodash/isNumber';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';

type SourceInfo = {
  name: string;
  key?: string;
  groupName: string;
  type: string;
};

@Options({
  name: 'CameraLabelsNew',
  components: { NIcon, NButton }
})
export default class CameraLabelsNew extends Vue {
  @Prop({ type: Array, required: false })
  readonly cameraGroupsIds?: Array<number>;

  @Prop({ type: Array, required: false })
  readonly camerasIds?: Array<number>;

  @Prop({ type: Number, required: false })
  readonly videoArchiveId?: number;

  @Prop({ type: String, default: '/' })
  readonly separator!: string;

  get computedItems(): SourceInfo[] {
    let result: SourceInfo[] = [];
    const getCameraInfo = (id: number) => ({ type: 'camera', name: this.getCameraName(id), groupName: this.getCameraGroupByCameraId(id) });
    const getVideoInfo = (id: number) => ({
      type: 'videos',
      name: this.getVideoArchiveName(id),
      groupName: this.getCameraGroupByVideoArchiveId(id)
    });
    const getCameraGroupInfo = (id: number) => ({ type: 'camera', name: this.getCameraName(-1), groupName: this.getCameraGroupName(id) });

    if (this.camerasIds?.length) {
      result = this.camerasIds.map(getCameraInfo);
    } else if (this.videoArchiveId) {
      result = [this.videoArchiveId].map(getVideoInfo);
    } else if (this.cameraGroupsIds?.length) {
      result = this.cameraGroupsIds.map(getCameraGroupInfo);
    }

    return result;
  }

  get formattedSeparator() {
    return ' ' + this.separator + ' ';
  }

  getCameraGroupName(id: number): string {
    const notFound = `(${this.$t('errors.camera_group.not_found')})`;
    return dataAssetsModule.getCameraGroupById(id)?.name ?? notFound;
  }

  getCameraName(cameraId: number): string {
    const notFound = `(${this.$t('errors.camera.not_found')})`;
    return dataAssetsModule.getCameraById(cameraId)?.name ?? notFound;
  }

  getVideoArchiveName(id: number): string {
    const notFound = `(${this.$t('errors.video_archive.not_found')})`;
    return dataAssetsModule.getVideoArchiveById(id)?.name ?? notFound;
  }

  getCameraGroupByCameraId(cameraId: number): string {
    const notFound = `(${this.$t('errors.camera_group.not_found')})`;
    const group = dataAssetsModule.getCameraById(cameraId)?.group;
    return isNumber(group) ? this.getCameraGroupName(group) : notFound;
  }

  getCameraGroupByVideoArchiveId(cameraId: number): string {
    const notFound = `(${this.$t('errors.camera_group.not_found')})`;
    const group = dataAssetsModule.getVideoArchiveById(cameraId)?.camera_group;
    return isNumber(group) ? this.getCameraGroupName(group) : notFound;
  }
}
