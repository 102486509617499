
import { EmptySettings } from '@/api/models/Settings';
import { differenceOf } from '@/definitions/common/utils';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import { configModule } from '@/store/config';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { NInputNumber, NSwitch } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NConfidence from '@/uikit/confidence/NConfidence.vue';
import NForm, { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { getNumberValidator } from '@/uikit/forms/validators';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { Options, Vue } from 'vue-class-component';
import { computeHoursInSeconds, computeSecondsInHours } from './utils';
import { aclModule } from '@/store/acl';
import EntityBlank from '@/components/common/EntityBlank.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import VMSCleanupForm from '@/components/settings/VMSCleanupForm.vue';
import { cloneDeep } from 'lodash';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';

const DEFAULT_CLEANUP_THRESHOLD_IN_SECONDS = 3600;

const VMSSettings = 'vms-settings';

@Options({
  name: 'SettingsPage',
  components: { VMSCleanupForm, NButton, NForm, NTabs, SettingsPageLayout, EntityBlank }
})
export default class SettingsPage extends Vue {
  tabName = 'face';
  newModel: IFormModel = {};

  get options() {
    return {
      VMSSettings
    };
  }

  get oldModel() {
    return settingsItemModule.item ?? EmptySettings;
  }

  get tabs() {
    const result = dataAssetsModule.availableObjects.map((objectName) => {
      return { name: objectName, label: this.$t(`common.${ObjectsSingleToMulti[objectName]}`, 'f') };
    });

    if (configModule.features.vms_enabled) {
      result.push({ name: VMSSettings, label: this.$t('settings.vms_cleanup_settings') });
    }
    return result;
  }

  get changes() {
    return differenceOf(this.newModel, this.oldModel);
  }

  get hasChanges(): boolean {
    return Object.keys(this.changes).length > 0;
  }

  get hasViewAcl(): boolean {
    return aclModule.getAccess('ffsecurity.view_runtimesetting');
  }
  get hasUpdateAcl(): boolean {
    return aclModule.getAccess('ffsecurity.change_runtimesetting');
  }

  get formLayout(): IFormLayout {
    if (this.tabName === VMSSettings) return [];
    const objectName = this.tabName;

    const threshold = {
      path: `${objectName}_confidence_threshold`,
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.generic_confidence_threshold',
      component: NConfidence,
      i18n_tooltip: 'common.confidence_desc',
      tooltipPlacement: 'bottom',
      props: { controls: true }
    };

    const jpegQuality = {
      path: 'thumbnail_jpeg_quality',
      i18n_label: 'settings.thumbnail_jpeg_quality',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-control-120 n-form-pad-10',
      component: NInputNumber,
      props: { units: '%', min: 50, max: 95 },
      validators: [getNumberValidator({ required: false, min: 50, max: 95 })]
    };

    const eventsCleanupSwitch = {
      classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-20',
      component: NSwitch,
      props: { i18n_label: 'settings.events_cleanup' },
      encode: (model: IFormModel, value: boolean) => {
        model[`${objectName}_events_max_matched_age`] = value
          ? Reflect.get(this.oldModel, `${objectName}_events_max_matched_age`) || DEFAULT_CLEANUP_THRESHOLD_IN_SECONDS
          : 0;
        model[`${objectName}_events_max_unmatched_age`] = value
          ? Reflect.get(this.oldModel, `${objectName}_events_max_unmatched_age`) || DEFAULT_CLEANUP_THRESHOLD_IN_SECONDS
          : 0;
        model[`${objectName}_events_max_fullframe_matched_age`] = value
          ? Reflect.get(this.oldModel, `${objectName}_events_max_fullframe_matched_age`) || DEFAULT_CLEANUP_THRESHOLD_IN_SECONDS
          : 0;
        model[`${objectName}_events_max_fullframe_unmatched_age`] = value
          ? Reflect.get(this.oldModel, `${objectName}_events_max_fullframe_unmatched_age`) || DEFAULT_CLEANUP_THRESHOLD_IN_SECONDS
          : 0;
      },
      decode: function (this: IFormContext) {
        return (
          this.model[`${objectName}_events_max_matched_age`] ||
          this.model[`${objectName}_events_max_unmatched_age`] ||
          this.model[`${objectName}_events_max_fullframe_matched_age`] ||
          this.model[`${objectName}_events_max_fullframe_unmatched_age`]
        );
      }
    };

    const eventsCleanupFields = [
      {
        path: `${objectName}_events_max_matched_age`,
        i18n_label: 'settings.delete_matched_events_older_than',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-350 n-form-control-120 n-form-pad-20',
        component: NInputNumber,
        props: { i18n_units: 'settings.hours' },
        validators: [getNumberValidator({ required: false, min: 0 })],
        decode: function (this: IFormContext) {
          return computeHoursInSeconds(this.model[`${objectName}_events_max_matched_age`]);
        },
        encode: function (this: IFormContext, _model: IFormModel, value: number) {
          this.model[`${objectName}_events_max_matched_age`] = computeSecondsInHours(value);
        }
      },
      {
        path: `${objectName}_events_max_unmatched_age`,
        i18n_label: 'settings.delete_unmatched_events_older_than',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-350 n-form-control-120 n-form-pad-10',
        component: NInputNumber,
        props: { i18n_units: 'settings.hours' },
        validators: [getNumberValidator({ required: false, min: 0 })],
        decode: function (this: IFormContext) {
          return computeHoursInSeconds(this.model[`${objectName}_events_max_unmatched_age`]);
        },
        encode: function (this: IFormContext, _model: IFormModel, value: number) {
          this.model[`${objectName}_events_max_unmatched_age`] = computeSecondsInHours(value);
        }
      },
      {
        path: `${objectName}_events_max_fullframe_matched_age`,
        i18n_label: 'settings.delete_full_frames_of_matched_events_older_than',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-350 n-form-control-120 n-form-pad-10',
        component: NInputNumber,
        props: { i18n_units: 'settings.hours' },
        validators: [getNumberValidator({ required: false, min: 0 })],
        decode: function (this: IFormContext) {
          return computeHoursInSeconds(this.model[`${objectName}_events_max_fullframe_matched_age`]);
        },
        encode: function (this: IFormContext, _model: IFormModel, value: number) {
          this.model[`${objectName}_events_max_fullframe_matched_age`] = computeSecondsInHours(value);
        }
      },
      {
        path: `${objectName}_events_max_fullframe_unmatched_age`,
        i18n_label: 'settings.delete_full_frames_of_unmatched_events_older_than',
        classes: 'label-m n-form-w-2 n-form-label-horizontal-350 n-form-control-120 n-form-pad-10',
        component: NInputNumber,
        props: { i18n_units: 'settings.hours' },
        validators: [getNumberValidator({ required: false, min: 0 })],
        decode: function (this: IFormContext) {
          return computeHoursInSeconds(this.model[`${objectName}_events_max_fullframe_unmatched_age`]);
        },
        encode: function (this: IFormContext, _model: IFormModel, value: number) {
          this.model[`${objectName}_events_max_fullframe_unmatched_age`] = computeSecondsInHours(value);
        }
      }
    ];

    const hasEvents = !configModule.config.extra_options?.includes('hide_events') ?? true;
    const hasEventsCleanup =
      this.newModel[`${objectName}_events_max_matched_age`] ||
      this.newModel[`${objectName}_events_max_unmatched_age`] ||
      this.newModel[`${objectName}_events_max_fullframe_matched_age`] ||
      this.newModel[`${objectName}_events_max_fullframe_unmatched_age`];

    const layout: IFormLayout = [threshold, jpegQuality];
    hasEvents && layout.push(eventsCleanupSwitch);
    hasEvents && hasEventsCleanup && layout.push(...eventsCleanupFields);

    return layout;
  }

  async loadSettings() {
    await settingsItemModule.get();
    this.newModel = cloneDeep(this.oldModel);
  }

  async saveSettings() {
    const valid = this.$refs.form?.validate() ?? true;

    if (!valid) {
      this.$refs.form?.displayErrors();
      return;
    }
    try {
      await settingsItemModule.saveFields(this.changes);
      await this.loadSettings();
    } catch (e) {
      console.error(e);
    }
  }

  async mounted() {
    await this.loadSettings();
  }

  async update() {
    await this.saveSettings();
  }
}
