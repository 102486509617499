import { AxiosRequestConfig } from 'axios';

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type BundleType = typeof BundleTypes[keyof typeof BundleTypes];
export const BundleTypes = {
  Cards: 'cards',
  CardObjects: 'objects',
  Puppeteer: 'puppeteer',
  TypedObjects: 'typed-objects',
  Lines: 'lines',
  Cases: 'cases'
} as const;

export type BundleSourceType = typeof BundleSourceTypes[keyof typeof BundleSourceTypes];
export const BundleSourceTypes = {
  Cards: 'cards',
  Objects: 'objects',
  Lines: 'lines',
  Cases: 'cases',
  Puppeteer: 'puppeteer'
} as const;

export type DefaultBundleModelKeys = 'isAcceptable' | 'getItemId' | 'getDestinationPath' | 'getParams' | 'itemResultIdFormatter' | 'itemResultPayloadFormatter';

export type BundleModel = {
  type: BundleType;
  sourceType: BundleSourceType;
  isAcceptable: (requestConfig: AxiosRequestConfig) => boolean;
  getItemId: (pathParts: string[], requestConfig: AxiosRequestConfig) => string;
  getDestinationPath: (pathParts: string[]) => string;
  getParams: (indexes: string[]) => Record<string, any>;
  itemResultIdFormatter: (v: any) => any;
  itemResultPayloadFormatter: (v: any) => any;
};

export type BundleConfig = {
  requestConfig: AxiosRequestConfig;
  bundleType: BundleType;
  destinationPath: string;
  itemId: string;
  resolveHandler: any;
  rejectHandler: any;
  promise: any;
};
