import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "camera-labels-new" }
const _hoisted_2 = { class: "label-m" }
const _hoisted_3 = { class: "camera-label-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.computedItems?.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.computedItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.key,
              class: "camera-labels__camera-link"
            }, [
              _createVNode(_component_NIcon, {
                name: item.type
              }, null, 8, ["name"]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(item.groupName) + _toDisplayString(_ctx.formattedSeparator) + _toDisplayString(item.name), 1)
            ]))
          }), 128))
        : _createCommentVNode("", true)
    ])
  ]))
}