import { DirectiveBinding, VNode } from 'vue';

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    function clickOutsideHandler(event: Event) {
      const target = event.target as Node;
      const isElementTarget = target === el;
      const isElementContainsTarget = el.contains(target);
      if (!isElementTarget && !isElementContainsTarget) {
        const outsideHandler = binding.value as any;
        outsideHandler(event, el);
      }
    }
    (el as any).clickOutsideHandler = clickOutsideHandler;
    document.body.addEventListener('click', (el as any).clickOutsideHandler);
  },
  beforeUnmount(el: HTMLElement) {
    document.body.removeEventListener('click', (el as any).clickOutsideHandler);
  }
};
