import { AxiosRequestConfig } from 'axios';

export function defaultIsAcceptable(config: AxiosRequestConfig) {
  const hasParams = Object.entries(config.params || {}).length > 0;
  return !hasParams;
}

export function defaultGetDestinationPath(parts: string[]) {
  if (parts.length > 0 && parts.length < 3) {
    const [model] = parts;
    return `/${model}/`;
  }
  throw new Error('Common model path should contains 1 or 2 parts. Got: ' + JSON.stringify(parts));
}

export function getTypedDestinationPath(parts: string[]) {
  if (parts.length > 1 && parts.length < 4) {
    const [model, type] = parts;
    return `/${model}/${type}/`;
  }
  throw new Error('Typed model path should contains 2 or 3 parts. Got: ' + JSON.stringify(parts));
}

export function getFullDestinationPath(parts: string[]) {
  return `/${parts.join('/')}/`;
}
