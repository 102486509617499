
import ClickOutside from '../directives/click-outside';
import { createStickDirective } from '../directives/stick';
import NIcon from '../icons/NIcon.vue';
import keyboardNavigationHandler from '../input-base/keyboard-navigation';
import NInputBase from '../input-base/NInputBase.vue';
import { isNil } from 'lodash';
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NSelectItems from './NSelectItems.vue';
import NSelectTags from './NSelectTags.vue';
import NSelectTagsCount from './NSelectTagsCount.vue';
import { ISelectItem, NSelectItemName, NSelectItemsWidth, NSelectItemsWidths, NSelectModelValue } from './types';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'NSelect',
  inheritAttrs: false,
  components: {
    NButton,
    NIcon,
    NInputBase,
    NSelectItems,
    NSelectTags,
    NSelectTagsCount
  },
  directives: { ClickOutside, Stick: createStickDirective<HTMLDivElement, HTMLDivElement>() }
})
export default class NSelect extends Vue {
  @Prop({ type: String })
  readonly prefixIcon?: string;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: Boolean, default: false })
  readonly clearIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeIcon!: boolean;

  @Prop({ type: [Object, Array, String], required: false })
  readonly modelValue!: NSelectModelValue;

  @Prop({ type: String, default: 'value' })
  readonly keyField!: string;

  @Prop({ type: Array, default: () => [] })
  readonly items!: ISelectItem[];

  @Prop({ type: Function })
  readonly loadItems!: (value: any) => ISelectItem[] | Promise<ISelectItem[]>;

  @Prop({ type: Function })
  readonly searchItems!: (value: string) => ISelectItem[] | Promise<ISelectItem[]>;

  @Prop({ type: String })
  readonly placeholder?: string;

  @Prop({ type: Boolean, default: false })
  readonly plain!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly selectonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly multiline!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly checkCurrent!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideChevron!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly showEmptyList!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly allowCreate!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly fillValueOnSelect!: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: Boolean, default: false })
  readonly loadOnExpand!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly contains: boolean = true;

  @Prop({ type: Boolean, default: false })
  readonly filtered!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly capitalizeLabels!: boolean;

  @Prop({ type: [String, Number], default: NSelectItemsWidths.Target })
  readonly itemsWidth!: NSelectItemsWidth;

  @Prop({ type: Boolean, default: true })
  readonly displayCreated!: boolean;

  private inputValue = '';
  private hiddenCount = 0;
  private newCounter = 1000;
  private collapsedTags = true;

  private resizeTime: number | null = null;

  private newItems: ISelectItem[] = [];
  private loadedItems: ISelectItem[] = [];
  private helperItems: ISelectItem[] = [];

  private helperExpanded = false;
  private teleportTo: Element | string = 'body';

  private rnd = Math.random();

  get itemsHashKey() {
    return `${this.rnd} ${this.label}`;
  }

  updateTeleportTo() {
    this.teleportTo = document.fullscreenElement || 'body';
  }

  created() {
    !this.loadOnExpand && this.loadModelValueItems();
    window.addEventListener('resize', this.resizeHandler);
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    this.resizeTime = new Date().getTime();
    this.updateHelperPosition();
  }

  get itemsStyle() {
    let width = 'auto';
    if (this.itemsWidth === NSelectItemsWidths.Target) {
      width = this.getTargetWidth() + 'px';
    } else {
      const widthNumber = Number(this.itemsWidth);
      !Object.is(NaN, widthNumber) && (width = widthNumber + 'px');
    }

    return { width };
  }

  get showInputField() {
    const showNotReadonlyNotMutliple = !this.readonly || !this.multiple;
    const showNotSelectonlyNotMultiple = !this.selectonly || !this.multiple;

    return showNotReadonlyNotMutliple && showNotSelectonlyNotMultiple;
  }

  getTargetWidth() {
    const rect = (this.resizeTime, this.$refs.target?.getBoundingClientRect());
    const minSize = 200;
    return Math.round(Math.max(rect?.width || minSize, minSize));
  }

  get allItems() {
    return [...this.items, ...this.loadedItems, ...(this.displayCreated ? this.newItems : [])];
  }

  get modelValueItem(): ISelectItem {
    const emptyItem = { value: null, label: '' };
    switch (this.keyField) {
      case '':
        return this.modelValue as ISelectItem;
      case 'value':
        return (this.allItems.find((item) => item.value === this.modelValue) as ISelectItem) || emptyItem;
      case 'label':
        return (this.allItems.find((item) => item.label === this.modelValue) as ISelectItem) || emptyItem;
    }
    return emptyItem;
  }

  get modelValueArray(): ISelectItem[] {
    if (this.modelValue instanceof Array) {
      let mapHandler = (v: any) => v;
      switch (this.keyField) {
        case '':
          return this.modelValue as ISelectItem[];
        case 'value': {
          mapHandler = (item: NSelectItemName) => this.allItems.find((v) => v.value === item) || { label: item, value: item };
          break;
        }
        case 'label': {
          mapHandler = (item: NSelectItemName) => this.allItems.find((v) => v.label === item) || { label: item, value: item };
          break;
        }
      }
      const result = (this.modelValue as any).map(mapHandler) as ISelectItem[];
      return result;
    } else {
      const modelValue = this.modelValue as string;
      return this.allItems.filter((item) => item.value === modelValue);
    }
  }

  get icon() {
    return this.modelValueItem && this.modelValueItem.icon;
  }

  get color() {
    return this.modelValueItem && this.modelValueItem.color;
  }

  get chevron() {
    if (this.hideChevron) return '';
    if ((this.closeIcon || this.clearIcon) && this.modelValue) return '';
    return this.helperExpanded ? 'chevron-up' : 'chevron-down';
  }

  get isValueNotEmpty() {
    return Array.isArray(this.modelValue) ? !!this.modelValue.length : this.modelValue !== undefined;
  }

  get clearIconVisible() {
    return this.clearIcon && this.isValueNotEmpty;
  }

  get formattedPlaceholder() {
    return !this.isValueNotEmpty ? this.placeholder : undefined;
  }

  getFilteredItems(value = '') {
    const searchString = value.toLowerCase();
    return this.allItems.filter((item) => {
      const label = item.label.toLowerCase();
      return this.contains ? label.includes(searchString) : label.startsWith(searchString);
    });
  }

  @Watch('modelValue')
  updateModelValue(v: any) {
    if (!this.multiple && this.modelValueItem) {
      this.updateInputValue(this.modelValueItem);
    }
  }

  emitModelValueItem(value: ISelectItem) {
    if (this.checkCurrent && !this.multiple) {
      this.allItems.forEach((item) => (item.checked = false));
      value.checked = true;
    }

    let emitValue: NSelectModelValue = value;
    if (this.keyField === 'value') emitValue = value.value;
    else if (this.keyField === 'label') emitValue = value.label;
    this.$emit('update:modelValue', emitValue);
  }

  emitModelValueArray(value: ISelectItem[]) {
    let emitValue: NSelectModelValue = value;
    if (this.keyField === 'value') emitValue = value.map((item) => item.value);
    else if (this.keyField === 'label') emitValue = value.map((item) => item.label);
    this.$emit('update:modelValue', emitValue);
  }

  async getHelperItems(value = ''): Promise<ISelectItem[]> {
    const hasSearchFunction = this.searchItems instanceof Function;
    const isAllowed = this.inputValue === value;
    const items = hasSearchFunction && isAllowed ? await this.searchItems(value) : this.selectonly ? this.allItems : this.getFilteredItems(value);
    const checkItemHandler = (item: ISelectItem) => ({ ...item, checked: this.modelValueArray.some((valueItem) => valueItem.value === item.value) });
    const result = items.map(checkItemHandler);
    return result;
  }

  async showHelper(value = '') {
    let helperItems: ISelectItem[] = await this.getHelperItems(value);
    helperItems = reactive(helperItems);

    if ((helperItems && helperItems.length) || this.showEmptyList) {
      this.helperItems = helperItems;
      this.helperExpanded = true;
      this.updateTeleportTo();
    } else {
      this.hideHelper();
    }
  }

  hideHelper() {
    this.helperExpanded = false;
    this.updateTeleportTo();
  }

  async toggleHelper() {
    if (this.helperExpanded) {
      this.hideHelper();
    } else {
      this.loadOnExpand && (await this.loadModelValueItems());
      await this.showHelper();
    }
  }

  updateHelperPosition() {
    nextTick(() => {
      const stickElementToTargetEx = this.$refs?.helper?.$el?.stickElementToTargetEx;
      if (stickElementToTargetEx instanceof Function) {
        stickElementToTargetEx();
      }
    });
  }

  async focusHandler(event?: FocusEvent) {
    this.loadOnExpand && (await this.loadModelValueItems());
    this.filtered ? await this.showHelper(this.inputValue) : await this.showHelper();
    await nextTick(() => this.$refs.input?.focus());
  }

  createNewTag() {
    let newTag: ISelectItem = {
      label: this.inputValue,
      value: this.newCounter++
    };
    this.newItems.push(newTag);
    this.inputValue = '';
    const newValue = [...this.modelValueArray];
    newValue.push(newTag);
    this.emitModelValueArray(newValue);
  }

  removeLastTag() {
    const newValue = [...this.modelValueArray];
    newValue.pop();
    this.emitModelValueArray(newValue);
  }

  closeTagHandler(item: ISelectItem) {
    const newValue = this.modelValueArray.filter((valueItem) => valueItem.value !== item.value);
    this.emitModelValueArray(newValue);
    this.hideHelper();
  }

  updateHelperVisible() {
    this.showHelper();
  }

  keydownHandler(e: KeyboardEvent) {
    if (this.readonly && e.key === 'Backspace') {
      this.removeLastTag();
      this.hideHelper();
    }
    if (this.allowCreate && e.key === 'Enter') {
      this.createNewTag();
    }
    keyboardNavigationHandler(e, this.helperExpanded, this.updateHelperVisible, 'n-select-items__item');
  }

  inputHandler(value: string) {
    this.inputValue = value;
    this.showHelper(value);
  }

  selectHandler(item: ISelectItem) {
    this.clearHandler();
    if (this.loadItems instanceof Function && !this.loadedItems.some((v) => v.value === item.value)) {
      this.loadedItems.push(item);
    }
    if (this.multiple) {
      let newValue = this.modelValueArray; // TODO: use clone object

      if (item.checked) {
        newValue.push(item);
      } else {
        newValue = newValue.filter((valueItem) => valueItem.value !== item.value);
      }

      this.emitModelValueArray(newValue);
      this.updateHelperPosition();
    } else {
      //this.inputValue = this.fillValueOnSelect && item.value ? item.label : '';
      this.updateInputValue(item);
      this.emitModelValueItem(item);
      this.hideHelper();
    }
  }

  updateInputValue(item: ISelectItem) {
    this.inputValue = this.fillValueOnSelect && !isNil(item.value) ? item.label : '';
  }

  closeByOutside(e: MouseEvent) {
    if (this.$refs.target.contains(e.target)) {
      return;
    }
    if (!this.readonly && this.modelValueItem) {
      this.updateInputValue(this.modelValueItem);
    }
    this.hideHelper();
  }

  clearHandler() {
    this.inputValue = '';
    if (this.multiple) {
      this.emitModelValueArray([]);
    } else {
      this.emitModelValueItem({ value: undefined, label: '' });
    }
  }

  closeHandler() {
    this.$emit('close');
  }

  hiddenCountChange(hiddenCount: number) {
    this.hiddenCount = hiddenCount;
  }

  focus() {
    this.focusHandler();
  }

  blurHandler() {
    this.hideHelper();
  }

  tagsCountClickHandler() {
    if (this.multiline) {
      this.collapsedTags = !this.collapsedTags;
    } else {
      this.focus();
    }
  }

  async loadModelValueItems() {
    if (this.loadItems instanceof Function) {
      let isLoaded = Boolean(this.modelValueItem?.value);
      if (this.multiple && Array.isArray(this.modelValue) && this.modelValue.length) {
        let loadedItemsMap = Object.fromEntries(this.loadedItems.map((item) => [item.value, true]));
        isLoaded = Boolean((this.modelValue as any).every((value: any) => !!loadedItemsMap[value]));
      }
      if (!isLoaded) this.loadedItems = await this.loadItems(this.modelValue);
    }

    if (!this.multiple && this.modelValueItem) {
      this.updateInputValue(this.modelValueItem);
    }
  }

  @Watch('modelValue', { immediate: true })
  modelValueWatcher() {
    !this.loadOnExpand && this.loadModelValueItems();
  }

  @Watch('items')
  setInputValue() {
    if (!this.multiple && this.modelValueItem) {
      this.updateInputValue(this.modelValueItem);
    }
  }
}
